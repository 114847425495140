export const brotherInfo = [
    {
        "id": 1.0,
        "name": "Richard Staebler",
        "gender": "M",
        "class": "Founding",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Founders/richardstaebler.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 2.0,
        "name": "Jonathan Wong",
        "gender": "M",
        "class": "Founding",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Founders/jonathanwong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 3.0,
        "name": "Sarah Leung",
        "gender": "F",
        "class": "Founding",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Founders/sarahleung.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 4.0,
        "name": "Jacqueline Kim",
        "gender": "F",
        "class": "Founding",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Founders/jacquelinekim.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 5.0,
        "name": "Terence Leung",
        "gender": "M",
        "class": "Founding",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Founders/terenceleung.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 6.0,
        "name": "Jonathan Turcios",
        "gender": "M",
        "class": "Founding",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Founders/jonathanturcios.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 7.0,
        "name": "Amy Nguyen",
        "gender": "F",
        "class": "Founding",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Earth System Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Founders/amynguyen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 8.0,
        "name": "Jeffrey Go",
        "gender": "M",
        "class": "Founding",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Founders/jeffreygo.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 9.0,
        "name": "Christopher Louie",
        "gender": "M",
        "class": "Founding",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Founders/christopherlouie.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 10.0,
        "name": "Charles Chiang ",
        "gender": "M",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/charleschiang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 11.0,
        "name": "Walter Hsieh",
        "gender": "M",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/walterhsieh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 12.0,
        "name": "Elliot Huang",
        "gender": "M",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/elliothuang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 13.0,
        "name": "Bryan Le ",
        "gender": "M",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/bryanle.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 14.0,
        "name": "Michelle Mueller",
        "gender": "F",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/michellemueller.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 15.0,
        "name": "Nazneen Pashutanizadeh",
        "gender": "F",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/nazneenpashutanizadeh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 16.0,
        "name": "Siddharthan Selvasekar",
        "gender": "M",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/siddharthanselvasekar.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 17.0,
        "name": "Patrick Sy ",
        "gender": "M",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/patricksy.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 18.0,
        "name": "Chynna Velasco",
        "gender": "F",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/chynnavelasco.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 19.0,
        "name": "Matthew Vera",
        "gender": "M",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/mattvera.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 20.0,
        "name": "Eric Wengert ",
        "gender": "M",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/ericwengert.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 21.0,
        "name": "Jack Zhai",
        "gender": "M",
        "class": "Alpha",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Environmental Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Alpha/jackzhai.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 22.0,
        "name": "Gabrielle Abdon",
        "gender": "F",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/gabrielleabdon.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 23.0,
        "name": "Evan Coombs ",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/evancoombs.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 24.0,
        "name": "Benjamin Halbach",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/benjaminhalbach.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 25.0,
        "name": "Kevin Huynh",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/kevinhuynh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 26.0,
        "name": "Kelly Inciong",
        "gender": "F",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/kellyinciong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 27.0,
        "name": "Shan Kim",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/shankim.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 28.0,
        "name": "Andrew Kin",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/andrewkin.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 29.0,
        "name": "Eddie Kwan",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/eddiekwan.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 30.0,
        "name": "Allison Ramirez",
        "gender": "F",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/allisonramirez.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 31.0,
        "name": "Laraib Saeed ",
        "gender": "F",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mathmatics",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/laraibsaeed.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 32.0,
        "name": "Ya Thao",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/yathao.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 33.0,
        "name": "Andrew Toledo",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/andrewtoledo.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 34.0,
        "name": "Dustin Vo",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Environmental Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/dustinvo.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 35.0,
        "name": "Jasper Wang",
        "gender": "M",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/jasperwang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 36.0,
        "name": "Yijiao (Jo) Wang",
        "gender": "F",
        "class": "Beta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Beta/yijaowang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 37.0,
        "name": "Divya Bajaj",
        "gender": "F",
        "class": "Gamma",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma/divyabajaj.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 38.0,
        "name": "Casey Carter ",
        "gender": "M",
        "class": "Gamma",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma/caseycarter.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 39.0,
        "name": "Jessica Kao",
        "gender": "F",
        "class": "Gamma",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma/jessicakao.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 40.0,
        "name": "Nicolas Kurtz",
        "gender": "M",
        "class": "Gamma",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma/nicholaskurtz.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 41.0,
        "name": "Amelia Meyer",
        "gender": "F",
        "class": "Gamma",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma/ameliameyer.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 42.0,
        "name": "Connie Phan",
        "gender": "F",
        "class": "Gamma",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma/conniephan.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 43.0,
        "name": "Ashley Robinson",
        "gender": "F",
        "class": "Gamma",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma/ashleyrobinson.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 44.0,
        "name": "Martin Boyd",
        "gender": "M",
        "class": "Delta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta/martinboyd.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 45.0,
        "name": "Yonathan Esquivel",
        "gender": "M",
        "class": "Delta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta/yonathanesquivel.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 46.0,
        "name": "Brian Mugg",
        "gender": "M",
        "class": "Delta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta/brianmugg.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 47.0,
        "name": "Adiseshu Peesapati",
        "gender": "M",
        "class": "Delta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta/adiseshupeesapati.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 48.0,
        "name": "Chris Prijic",
        "gender": "M",
        "class": "Delta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta/chrisprijic.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 49.0,
        "name": "Irvin Vazquez ",
        "gender": "M",
        "class": "Delta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta/irvinvasquez.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 50.0,
        "name": "Telena Vo ",
        "gender": "F",
        "class": "Delta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Environmental Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta/telenavo.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 51.0,
        "name": "Darren Yang ",
        "gender": "M",
        "class": "Delta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta/darrenyang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 52.0,
        "name": "Juan Contreras",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/juancontreras.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 53.0,
        "name": "Lynn Dannan",
        "gender": "F",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/lynndannan.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 54.0,
        "name": "Anthony Foley",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/anthonyfoley.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 55.0,
        "name": "Daniel Klebe",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/danielklebe.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 56.0,
        "name": "Alex LaVelle",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/alexlavelle.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 57.0,
        "name": "Bryce Loop",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/byrceloop.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 58.0,
        "name": "Rio Menchaca",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/riomenchaca.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 59.0,
        "name": "Colleen Muawad",
        "gender": "F",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemistry",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/colleenmuawad.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 60.0,
        "name": "Milan Odedra",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/milanodedra.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 61.0,
        "name": "Brian Seng",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/brianseng.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 62.0,
        "name": "Narumi Takagi",
        "gender": "F",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/narumitakagi.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 63.0,
        "name": "Kush Thukral",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/kushthukral.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 64.0,
        "name": "Linda Ton",
        "gender": "F",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Environmental Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/lindaton.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 65.0,
        "name": "Yen Tu",
        "gender": "F",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/yentu.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 66.0,
        "name": "Chelsea Yuen",
        "gender": "F",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/chelseayuen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 67.0,
        "name": "Han Zheng",
        "gender": "M",
        "class": "Epsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon/hanzheng.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 68.0,
        "name": "Brandon Chen",
        "gender": "M",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/brandonchen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 69.0,
        "name": "Xavier Chuck",
        "gender": "M",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/xavierchuck.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 70.0,
        "name": "Itzetl Frausto",
        "gender": "F",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/itzetlfrausto.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 71.0,
        "name": "Ryan Hai",
        "gender": "M",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/ryanhai.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 72.0,
        "name": "Winnie Hu",
        "gender": "F",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/winniehu.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 73.0,
        "name": "Ryan Kawahara",
        "gender": "M",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/ryankawahara.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 74.0,
        "name": "Christine Lao ",
        "gender": "F",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/christinelao.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 75.0,
        "name": "Bryan Luong",
        "gender": "M",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/bryanloung.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 76.0,
        "name": "Meghan Matibag",
        "gender": "F",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/meghanmatibag.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 77.0,
        "name": "Mae Tamayo ",
        "gender": "F",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/maetamayo.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 78.0,
        "name": "Anita Tran",
        "gender": "F",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/anitatran.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 79.0,
        "name": "James Yi ",
        "gender": "F",
        "class": "Zeta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta/jamesyi.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 80.0,
        "name": "Jayvee Aspa",
        "gender": "M",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/jayveeaspa.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 81.0,
        "name": "Sandy Dao",
        "gender": "F",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/sandydao.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 82.0,
        "name": "Vivian Hang",
        "gender": "F",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/vivianhuang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 83.0,
        "name": "William Hsueh",
        "gender": "F",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Materical Science Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/williamhsueh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 84.0,
        "name": "Jevons Jiang",
        "gender": "M",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/jevonsjiang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 85.0,
        "name": "Jodie Loo",
        "gender": "F",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/jodieloo.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 86.0,
        "name": "David Pham",
        "gender": "M",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/davidpham.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 87.0,
        "name": "Weiqiang (Jesse) Yin",
        "gender": "M",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Undeclared Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/weiqing(jessie)yin.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 88.0,
        "name": "Celina Chen",
        "gender": "F",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering & Economics",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/celinachen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 89.0,
        "name": "Cesar Ramales",
        "gender": "M",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemcial Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/cesarramales.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 90.0,
        "name": "Amanda Reth",
        "gender": "F",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Environmental Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/amandareth.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 91.0,
        "name": "Brittany Tran",
        "gender": "F",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Environmental Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/brittanytran.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 92.0,
        "name": "Michelle (Ana) Tulean",
        "gender": "F",
        "class": "Eta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Eta/michelle(ana)tulean.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 93.0,
        "name": "Rodney Buzon",
        "gender": "M",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Materical Science Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/rodneybuzon.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 94.0,
        "name": "Baldwin Chang",
        "gender": "M",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/baldwinchang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 95.0,
        "name": "Samantha Huang",
        "gender": "F",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/samanthahuang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 96.0,
        "name": "Jackie Li ",
        "gender": "F",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/jackieli.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 97.0,
        "name": "GuanYu Liu",
        "gender": "F",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/guanyuliu.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 98.0,
        "name": "Andrew Melendez",
        "gender": "M",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/andrewmelendez.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 99.0,
        "name": "Eric Nguyen",
        "gender": "M",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/ericnguyen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 100.0,
        "name": "Marlen Rojas ",
        "gender": "F",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/marlenrojas.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 101.0,
        "name": "David Tran",
        "gender": "M",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/davidtran.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 102.0,
        "name": "Derek Yeh ",
        "gender": "M",
        "class": "Theta",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Theta/derekyeh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 103.0,
        "name": "Michael Acasio",
        "gender": "M",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/michaelacasio.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 104.0,
        "name": "Bridget Ventura",
        "gender": "F",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/bridgetventura.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 105.0,
        "name": "Tina Chan",
        "gender": "F",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Materical Science Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/tinachan.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 106.0,
        "name": "Allen Chang",
        "gender": "M",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/allenchang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 107.0,
        "name": "Daniel Duong",
        "gender": "M",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/danielduong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 108.0,
        "name": "Dean Gonzales",
        "gender": "M",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/deangonzales.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 109.0,
        "name": "Dale Lee",
        "gender": "M",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/dalelee.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 110.0,
        "name": "Sean Chih Wei Lee",
        "gender": "M",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/seanchihweilee.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 111.0,
        "name": "Sergio Linares",
        "gender": "M",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/sergiolinares.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 112.0,
        "name": "Vishal Mandalia",
        "gender": "M",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/vishalmandalia.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 113.0,
        "name": "Dan Tang ",
        "gender": "F",
        "class": "Iota",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Iota/dantang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 114.0,
        "name": "Santiago Barrera",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/santiagobarrera.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 115.0,
        "name": "Heejun Chough",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Material Science Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/heejunchough.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 116.0,
        "name": "Jimmy Chung",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/jimmychung.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 117.0,
        "name": "Yun Jo (Kevin)",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/yunjo(kevin)chun.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 118.0,
        "name": "Henry Hoang",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/henryhoang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 119.0,
        "name": "Joseph Hong",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/josephhong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 120.0,
        "name": "Kyle Krumrei ",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/kylekrumrei.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 121.0,
        "name": "Jason Lee ",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/jasonlee.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 122.0,
        "name": "Keith Leung",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/keithleung.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 123.0,
        "name": "Kevin Nguyen",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/kevinnguyen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 124.0,
        "name": "Leianne Roylo",
        "gender": "F",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/leianneroylo.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 125.0,
        "name": "Brendon Tran",
        "gender": "M",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/brendontran.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 126.0,
        "name": "Sophia Ynami ",
        "gender": "F",
        "class": "Kappa",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Kappa/sophiaynami.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 127.0,
        "name": "Lawrence Chong",
        "gender": "M",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/lawrencechong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 128.0,
        "name": "Alvin Kwong",
        "gender": "M",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/alvinkwong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 129.0,
        "name": "Calvin Li",
        "gender": "M",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Material Science Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/calvinli.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 130.0,
        "name": "Yu Wei Liu ",
        "gender": "M",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/yuweiliu.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 131.0,
        "name": "John Luong",
        "gender": "M",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/johnluong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 132.0,
        "name": "Jessica Ma",
        "gender": "F",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/jessicama.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 133.0,
        "name": "Laura Marsh",
        "gender": "F",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Software Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/lauramarsh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 134.0,
        "name": "Phi Nguyen",
        "gender": "M",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/phinguyen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 136.0,
        "name": "Nathan Padrid",
        "gender": "M",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Software Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/nathanpadrid.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 137.0,
        "name": "Michael Pagdonsolan",
        "gender": "M",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/michaelpagdonsolan.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 138.0,
        "name": "Aina Tancinco",
        "gender": "F",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/ainatancinco.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 139.0,
        "name": "Viet Tran",
        "gender": "M",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/viettran.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 140.0,
        "name": "Mandy Weng ",
        "gender": "F",
        "class": "Lambda",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Lambda/mandyweng.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 141.0,
        "name": "Christopher Banh",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/christopherbanh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 142.0,
        "name": "Steven Campos ",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/stevencampos.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 143.0,
        "name": "Jessica Chen",
        "gender": "F",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/jessicachen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 144.0,
        "name": "John Chua",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/johnchua.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 145.0,
        "name": "Brian Dang",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/briandang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 146.0,
        "name": "Kevin Troy De Guzman",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/kevintroydeguzman.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 147.0,
        "name": "Sebastian Gao",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/sebastiangao.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 148.0,
        "name": "Tony Ho",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/tonyho.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 149.0,
        "name": "Agnes Jang",
        "gender": "F",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/agnesjang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 150.0,
        "name": "Tony Lao",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/tonylao.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 151.0,
        "name": "Theresa Le",
        "gender": "F",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/theresale.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 152.0,
        "name": "Ruth Nguyen",
        "gender": "F",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/ruthnguyen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 153.0,
        "name": "Johnny Pham",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/johnnypham.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 154.0,
        "name": "Judith Rupasinghe",
        "gender": "F",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/judithrupasinghe.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 155.0,
        "name": "Kayla San Pedro",
        "gender": "F",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/kaylasanpedro.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 156.0,
        "name": "Michelle Vasquez ",
        "gender": "F",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/michellevasquez.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 157.0,
        "name": "Kevin Wong",
        "gender": "M",
        "class": "Mu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Mu/kevinwong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 158.0,
        "name": "Chloe Agape",
        "gender": "F",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/chloeagape.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 159.0,
        "name": "Kevin Chen",
        "gender": "M",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/kevinchen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 160.0,
        "name": "Willis Lao",
        "gender": "M",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/willislao.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 162.0,
        "name": "Dawn Malla",
        "gender": "F",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/dawnmalla.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 163.0,
        "name": "Shogo Nakamura ",
        "gender": "M",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/shogonakamura.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 164.0,
        "name": "Hailey Noe",
        "gender": "F",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/haileynoe.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 165.0,
        "name": "Kyla Quilos",
        "gender": "F",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/kylaquilos.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 166.0,
        "name": "Jose Santillan",
        "gender": "M",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/josesantillan.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 167.0,
        "name": "Yvonne Szeto",
        "gender": "F",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/yvonneszeto.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 168.0,
        "name": "Claire Xie",
        "gender": "F",
        "class": "Nu",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/claire-xie-7a7b61148/",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Nu/clairexie.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 169.0,
        "name": "Karla Abad-Torrez",
        "gender": "F",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/karlaabadtorrez.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 170.0,
        "name": "Maxine Apoderado",
        "gender": "F",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/maxineapoderado.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 171.0,
        "name": "Eric Chen",
        "gender": "M",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/ericchen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 172.0,
        "name": "Paul John Evangelista (PJ) ",
        "gender": "M",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/pauljohnevangelista.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 173.0,
        "name": "Sarah Huang",
        "gender": "F",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "http://linkedin.com/in/sarah-huang-241520154",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/sarahhuang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 174.0,
        "name": "Justin Huynh",
        "gender": "M",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/justinhuynh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 175.0,
        "name": "Jessica Lee",
        "gender": "F",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/jessicalee.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 176.0,
        "name": "Lucy Liang",
        "gender": "F",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/lucyliang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 177.0,
        "name": "Amanda Lin",
        "gender": "F",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/amandalin.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 178.0,
        "name": "Megan Nguyen",
        "gender": "F",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/megannguyen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 179.0,
        "name": "Harry Pak",
        "gender": "M",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/harrypak.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 180.0,
        "name": "Jason Tang ",
        "gender": "M",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/jasontang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 181.0,
        "name": "Hannad Trinh ",
        "gender": "F",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/hannadtrinh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 182.0,
        "name": "Vincent Villacorta",
        "gender": "M",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/vincentvillacorta.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 183.0,
        "name": "Jeffery Wong",
        "gender": "M",
        "class": "Xi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Xi/jefferywong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 184.0,
        "name": "Kulraj Dhaliwal ",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/kulrajdhaliwal.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 185.0,
        "name": "Lawrence Hwang",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/lawrencehwang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 186.0,
        "name": "Chandan Jain",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/chandanjain.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 187.0,
        "name": "Christopher La",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/christopherla999/",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/christopherla.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/christopherla_casual.jpg?raw=true",
        "blurb": "My name is Christopher La and I am a 4th year mechanical engineer; some of my interests include 3D design, passive income and gaming. Most of the time you will find me spending time with my friends or family. My ultimate goal is to retire as soon as possible and spend time with the people I care about :) "
    },
    {
        "id": 188.0,
        "name": "Angelou Lao",
        "gender": "F",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "linkedin.com/in/angelou-lao/",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/angeloulao.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/angeloulao_casual.jpeg?raw=true",
        "blurb": "I like cheese! Professional interests include research, point-of-care, health care devices and tissue engineering. Current personal interests include learning Tik Tok recipes and beating anyone who wants it in MarioKart. "
    },
    {
        "id": 189.0,
        "name": "Gary Li ",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/garyli.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 190.0,
        "name": "Louis Lin ",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "http://linkedin.com/in/louis-lin31",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/louislin.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 191.0,
        "name": "Vivian Nguyen",
        "gender": "F",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/vivian-truong-nguyen/",
        "major": "Software Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/viviannguyen.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/viviannguyen_casual.jpeg?raw=true",
        "blurb": "future cat mom to a naked cat named chicken, future dog mom to a weenie dog named nugget"
    },
    {
        "id": 192.0,
        "name": "Danny Nguyen",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/dannynguyen.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/dannynguyen_casual.jpg?raw=true",
        "blurb": ""
    },
    {
        "id": 193.0,
        "name": "Nicholas Silaphet ",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/nicholassilaphet.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 194.0,
        "name": "Tori Spiegel",
        "gender": "F",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "linkedin.com/in/victoriaspiegel",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/victoriaspiegel.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 195.0,
        "name": "Alexis Vu",
        "gender": "F",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/alexisvu.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 196.0,
        "name": "Victoria Vuong",
        "gender": "F",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/victoriavuong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 197.0,
        "name": "Taekyoo Won ",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/taekyoowon/",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/taekyoowon.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 198.0,
        "name": "Kelly Wong",
        "gender": "F",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/kelly-briana-wong/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/kellywong.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/kellywong_casual.jpg?raw=true",
        "blurb": "I love staying active by working out, going for runs, and playing basketball. I also enjoy spending time with family and friends. During my time in Irvine, my favorite way to destress was going to Disneyland with my friends!  "
    },
    {
        "id": 199.0,
        "name": "Ryan Zhen",
        "gender": "M",
        "class": "Omicron",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/ryan-zhen/",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Omicron/ryanzhen.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/ryanzhen_casual.JPG?raw=true",
        "blurb": ""
    },
    {
        "id": 200.0,
        "name": "Micky Chettanapanich",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/mickychettanapanich.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 201.0,
        "name": "Nicholas Chiu ",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/nicholaschiu.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 202.0,
        "name": "Yoseph Ghazal ",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "http://linkedin.com/in/yosephghazal",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/yosephghazal.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 203.0,
        "name": "Justin Ho",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/justin-ho627/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/justinho.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/justinho_casual.jpg?raw=true",
        "blurb": "Hey! I'm Justin and a Computer Science Major. I'm always down to try new things and some of my hobbies include eating, video editing, and tricking. Always down to skip sleep so hmu in the AM's"
    },
    {
        "id": 204.0,
        "name": "Albert Hong",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "http://linkedin.com/in/albert-hong-bb7825183",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/alberthong.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/alberthong_casual.JPG?raw=true",
        "blurb": "Hi guys! My name is Albert Hong, and I am a Biomedical Engineering major at UCI with a specialization in nano and micro technologies. Outside of school, some of my hobbies include fitness (specifically weightlifting) and watching Netflix and anime. I also love listening to different kinds of music in my free time with some of my favorite genres including rap and R&B. "
    },
    {
        "id": 205.0,
        "name": "Kevin Huynh",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/kevinhuynh.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/kevinhuynh_casual.jpeg?raw=true",
        "blurb": "Hi! My name is Kevin and I am currently pursuing a career in software engineering. I chose to study this field initially because my relatives are software engineers and encouraged me to try programming since I was an Undeclared major. After stepping into this field, I saw computer science as a valuable skill of this generation and something to exercise/challenge my desire to create. I also enjoy playing basketball, dancing and singing. But more importantly, I enjoy using my time to sit and converse with my fellow companions about more personal subjects."
    },
    {
        "id": 206.0,
        "name": "Dylan Kao",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "linkedin.com/in/meganlanvynguyen",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/dylankao.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/dylankao_casual.jpg?raw=true",
        "blurb": "Hello my name is Dylan Kao. I love fried chicken and bananas."
    },
    {
        "id": 207.0,
        "name": "Aaron Liang",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/aaronhliang",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/aaronliang.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/a9fa5e5d5c99c5a8cc3b16cf03d114b7c12430fc/casual-photos/aaronliang_casual%20-%20Aaron%20Liang.jpg?raw=true",
        "blurb": "What's poppin, I made this website. Hope you like it "
    },
    {
        "id": 208.0,
        "name": "David Luong",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/davidluong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 209.0,
        "name": "Anderson Nguyen",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/andersonnguyen.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/andersonnguyen_casual.JPG?raw=true",
        "blurb": "Hi! I love basketball, photography, and guitar. Hmu anytime if you want to do any of these activities!"
    },
    {
        "id": 210.0,
        "name": "Andrew Nguyen",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "Linkedin.com/in/andrewnguyen11",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/andrewnguyen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 211.0,
        "name": "Lynette Nguyen",
        "gender": "F",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/lynettenguyen14/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/lynettenguyen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 213.0,
        "name": "Lily Pham",
        "gender": "F",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/phamlily/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/lilypham.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 214.0,
        "name": "Cameron Sherry",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/cameronsherry.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 216.0,
        "name": "Areeta Wong",
        "gender": "F",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/areeta",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/areetawong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 217.0,
        "name": "Jeffrey Xu ",
        "gender": "M",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/xujeffreyc/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/jeffreyxu.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/jeffreyxu_casual.png?raw=true",
        "blurb": "Hi I'm Jeffrey! I'm currently a third year computer science student. I love going outdoors whether it be playing basketball or running. I've been playing Valorant for a while, but I'm still Iron. I've also been doing my best to cook more and more, but after having a hard boiled egg explode on me after microwaving it, I have reconsidered this hobby. Other than these things, I really enjoy watching kdramas and animes (currently watching jujitsu kaisen :) )"
    },
    {
        "id": 218.0,
        "name": "Lauren Yoo",
        "gender": "F",
        "class": "Pi",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/lauren-yoo/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Pi/laurenyoo.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/laurenyoo_casual.jpg?raw=true",
        "blurb": "Hello! My name is Lauren and I'm a Computer Science Major. Aside from being a Brother in Theta Tau, I am also a Hack At UCI Corporate Organizer and a Quokka Brew Brand Ambassador. For fun I love to snowboard and drive cars!"
    },
    {
        "id": 219.0,
        "name": "Jonathan Chang",
        "gender": "M",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/changster747",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/jonathanchang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 220.0,
        "name": "Maireen Espiritu",
        "gender": "F",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Software Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/maireenespiritu.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/maireenespiritu_casual.JPG?raw=true",
        "blurb": "Hello! My name is Maireen Espiritu, and I am currently a third year software engineering major at UCI! I crossed in Fall 2019 as a part of the Rho class. In the future, I either want to go into web/application development or surgical robotics programming and have recently accepted a UI/UX front-end internship with Vien Health that I will start in the spring! As of my involvements on campus, I'm apart of Kababayan at UCI as the current Rural PACN Coordinator, am a camp counselor for CampMed, and am the current Media Chair for Theta Tau. In the spring, I will begin my term as Director of New Member Education for this chapter, and in Fall 2021, I will begin my term as Vice Regent! I really love the environment that Theta Tau has given me for the past year, and that's part of the reason why I am so passionate about giving back to this organization. I'm so excited to see what the future of the organization has in store for my brothers and I."
    },
    {
        "id": 221.0,
        "name": "Emily Hoang",
        "gender": "F",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/mwlite/in/emilyhhoang",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/emilyhoang.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/emilyhoang_casual.jpg?raw=true",
        "blurb": "Hello! My name is Emily Hoang and I am currently a fourth year Biomedical Engineering major specializing in Biophotonics and minoring in Materials Science Engineering. Throughout college, I have grown to love working on biomedical devices and most importantly helping others in need! Some of my hobbies include learning about (and spending all my money on) skincare, making kombucha, and trying new foods."
    },
    {
        "id": 222.0,
        "name": "Eric Hsueh",
        "gender": "M",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/erichsueh3/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/erichsueh.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 223.0,
        "name": "Benjamin Huynh",
        "gender": "M",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/benjaminhuynh.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/benjaminhuynh_casual.png?raw=true",
        "blurb": "Hello! I am Ben Huynh and am a part of C/O 2022! I’ve always enjoyed coding and software development, even going back to high school. However, I initially went to UCI as a bio major, but eventually switched into Computer Science my second year, so you may say it was a complete switch-up! Aside from that, I am a fish – I can only play land sports (swimming/water polo), but cannot play land sports for the life of me. My favorite foods are shabu shabu, ramen, and pizza! If you have any questions about my collegiate journey, my professional life, or anything really, feel free to message me on LinkedIn!"
    },
    {
        "id": 224.0,
        "name": "Claire Hyon",
        "gender": "F",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/clairehyon.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/clairehyon_casual.jpeg?raw=true",
        "blurb": "Hello!!! I’m Claire and I am currently studying to major in Computer Science and Engineering. Some fun facts about me are that I actually used to be a synchronized swimmer (now known as artistic swimmer) and I broke my tooth while eating a sandwich, which I replaced with a fake tooth after and then the fake tooth FELL OUT while I was eating a gumdrop!!! Oh! I also forgot to mention that I adopted a little kitty during quarantine and her name is Delilah!! If you want to ask about my tooth or cat or just want to get to know me, please feel free to reach out!! I promise that I don’t bite :”) "
    },
    {
        "id": 225.0,
        "name": "Adrienne Lee",
        "gender": "F",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering & Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/adriennelee.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 226.0,
        "name": "Ryan Liao",
        "gender": "M",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "linkedin.com/in/rliao4",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/ryanliao.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 227.0,
        "name": "Lauren Lin",
        "gender": "F",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/laurenlin.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/laurenlin_casual.jpeg?raw=true",
        "blurb": "Hi!! My name is Lauren Lin and I am a second-year Mechanical Engineering student. I grew up in San Jose and have two dogs. I have a weak spot for spicy chips and baked goods. I love going on drives at night and having long talks with friends. One day I hope to live somewhere outside of California (temporarily)!!"
    },
    {
        "id": 228.0,
        "name": "Joshua Liu",
        "gender": "M",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/joshua-liu/",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/joshualiu.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/joshualiu_casual?raw=true",
        "blurb": "Hi! I’m Joshua Liu, a second year Computer Science and Engineering Major! I’m one of the current Rush Directors so send any questions you have about Theta Tau or rush my way! I joined my first year fall and it has been one of the best experiences of my life getting to meet my second family here on campus! Things I enjoy to do besides just school are cooking, bouldering, going to the driving range with my housemate, and playing guitar. I absolutely love music as it has been a huge part of my life ever since I could remember. I listen to almost every genre: alternative, emo, punk, screamo, pop, r&b, Kr&b, EDM, rap, the list goes on… So send me your music recs!"
    },
    {
        "id": 229.0,
        "name": "Tam Nguyen",
        "gender": "M",
        "class": "Rho",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Rho/tamnguyen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 230.0,
        "name": "Alyssa Harvey",
        "gender": "F",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/alyssajharvey/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/alyssaharvey.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 232.0,
        "name": "Teryn Kum",
        "gender": "F",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/teryn-kum",
        "major": "Software Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/terynkum.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 233.0,
        "name": "Hana Lee",
        "gender": "F",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "http://linkedin.com/in/hanalee394",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/hanalee.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/hanalee_casual.jpg?raw=true",
        "blurb": "Hi everyone! My name is Hana and I'm a Computer Science major! I actually came into college without any prior programming experience, but now I can't imagine studying anything else. With my major, I hope to continue finding future opportunities to grow and contribute my skills in the technological industry as a software engineer. Being able to join Theta Tau has been a blessing in itself creating memories with a group of passionate and fun individuals while professionally developing together! Outside of the fraternity, I enjoy dancing as I'm a part of a competitive hip-hop dance team on campus called CADC. You can also catch me strolling through Trader Joe's, buying all sorts of candles, trying to get into the thrifting scene, or in search of new foods/adventures in my free time :))"
    },
    {
        "id": 234.0,
        "name": "Claire Lin",
        "gender": "F",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "http://linkedin.com/in/clairelin1",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/clairelin.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 235.0,
        "name": "Lewis Lin",
        "gender": "M",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/lewislin9/",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/lewislin.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/lewislin_casual.JPG?raw=true",
        "blurb": "Hello! I'm Lewis Lin and I'm currently a graduate student at UCI studying civil/structural engineering. Some of my favorite things to do is go out with friends, play games and travel. One fun fact about me is that I'm from Utah. Go Jazz!"
    },
    {
        "id": 236.0,
        "name": "Crystal Grace David Militante",
        "gender": "F",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/crystalmilitante/",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/crystalmilitante.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 237.0,
        "name": "Thu Nguyen",
        "gender": "F",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/thu-inguyen",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 238.0,
        "name": "Alec Owen",
        "gender": "M",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/alecowen21",
        "major": "Chemical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/alecowen.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 239.0,
        "name": "Cyre Jorin To",
        "gender": "M",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/cyrejorinto.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 240.0,
        "name": "Christine Tran",
        "gender": "F",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "https://www.linkedin.com/in/christine-m-tran/",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/christinetran_casual.jpg?raw=true",
        "blurb": "Hi! I'm Christine, a third-year Mechanical Engineering student, and hope to make a positive impact on those around me through the work that I do. I enjoy working out during my free time and am a current member of UCI's Dragon Boat team! Spending time with those I love brings me tremendous joy; that's why I enjoy baking for and with others, playing games (card games, online games, etc.), and simply being around other people. I'm looking forward to the future, since you never know what's coming next!"
    },
    {
        "id": 241.0,
        "name": "Jeffrey Truong",
        "gender": "M",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/jeffreytruong.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/jeffreytruong_casual.jpg?raw=true",
        "blurb": "Hello my name is Jeffrey Truong. I eat chicken nuggets without the sauce. "
    },
    {
        "id": 242.0,
        "name": "Nathan Twigg",
        "gender": "M",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "https://github.com/Theta-Tau-at-UCI/pi-delta-brothers-images/blob/main/casual-photos/nathantwigg_casual.jpg?raw=true",
        "blurb": "I'm a 3rd year Computer Engineering major - originally from Pittsburgh Pennsylvania, and I used to be a bartender."
    },
    {
        "id": 243.0,
        "name": "Trinity Wong",
        "gender": "F",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "Public Relations",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 244.0,
        "name": "Karen Yip",
        "gender": "F",
        "class": "Tau",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Tau/karenyip.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 245.0,
        "name": "Lee Bahir",
        "gender": "F",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "Media",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/leebahir.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    }
    ,
    {
        "id": 246.0,
        "name": "Shlok Bansal",
        "gender": "M",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/shlokbansal.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 247.0,
        "name": "Riya Goja",
        "gender": "F",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/riyagoja.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 248.0,
        "name": "Kelly Huang",
        "gender": "F",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/kellyhuang.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 249.0,
        "name": "Long Lau",
        "gender": "M",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/longlau.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 250.0,
        "name": "Sharon Le",
        "gender": "F",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "Recruitment",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/sharonle.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 251.0,
        "name": "Danson Ma",
        "gender": "M",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "Recruitment",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/dansonma.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 252.0,
        "name": "Nicole Ostrowski",
        "gender": "F",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/nicoleostrowski.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 253.0,
        "name": "Natalie Poist",
        "gender": "F",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/nataliepoist.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 254.0,
        "name": "Ian Satoda",
        "gender": "M",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/iansatoda.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 255.0,
        "name": "Katherine Thai",
        "gender": "F",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/katherinethai.JPG",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 256.0,
        "name": "John Walling",
        "gender": "M",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/johnwalling.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 257.0,
        "name": "Zilong Zhao",
        "gender": "M",
        "class": "Upsilon",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Upsilon/zilong.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 258.0,
        "name": "Kameron Ahmed",
        "gender": "M",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Phi/kameronahmed.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 259.0,
        "name": "Kayla Choi",
        "gender": "F",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 260.0,
        "name": "Frederick Halo",
        "gender": "M",
        "class": "Phi",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 261.0,
        "name": "Jonathan Ho",
        "gender": "M",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Phi/jonathanho.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 262.0,
        "name": "Yessica Marin-Lopez",
        "gender": "F",
        "class": "Phi",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/yessicamarinlopez/",
        "major": "Chicano Latin Studies",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Phi/yessicamarinlopez.JPG",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 263.0,
        "name": "Derek Ortiz",
        "gender": "M",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 264.0,
        "name": "Navya Sangam",
        "gender": "F",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Phi/navyasangam.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 265.0,
        "name": "Sue Yen Tan",
        "gender": "F",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 266.0,
        "name": "Yan Tran",
        "gender": "F",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 267.0,
        "name": "Eric Wang",
        "gender": "M",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 268.0,
        "name": "Vishaal Yalamanchali",
        "gender": "M",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 269.0,
        "name": "Maria Zamudio",
        "gender": "F",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 270.0,
        "name": "Tyler Buffa",
        "gender": "M",
        "class": "Chi",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/tyler-buffa/",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/tylerbuffa.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 271.0,
        "name": "Daniela Campuzano",
        "gender": "F",
        "class": "Chi",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/daniela-yanira-campuzano/",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/danielacampuzano.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 272.0,
        "name": "Clara Chao",
        "gender": "F",
        "class": "Chi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "Scribe",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/clarachao.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 273.0,
        "name": "Ruijia (Ray) Hua",
        "gender": "M",
        "class": "Chi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Software Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/rayhua.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 274.0,
        "name": "Brandon Keung",
        "gender": "M",
        "class": "Chi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Data Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "Engineering",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/brandonkeung.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 275.0,
        "name": "Derek Ortiz",
        "gender": "M",
        "class": "Phi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 276.0,
        "name": "Yi Sien (Ian) Ku",
        "gender": "M",
        "class": "Chi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "Website Development",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/ianku.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 277.0,
        "name": "Ruth Regi",
        "gender": "F",
        "class": "Chi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 278.0,
        "name": "Joshua Serrano",
        "gender": "M",
        "class": "Chi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Electrical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/joshserrano.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 279.0,
        "name": "Brianna Steier",
        "gender": "F",
        "class": "Chi",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/briannasteier/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/briannasteier.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 280.0,
        "name": "Emily Truong",
        "gender": "F",
        "class": "Chi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Data Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/emilytruong.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 281.0,
        "name": "Abigail Villasis",
        "gender": "F",
        "class": "Chi",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Chi/abigailvillasis.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 282.0,
        "name": "Kenny Tang",
        "gender": "M",
        "class": "NULL",
        "active_status": "N",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "Professional Development",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpeg",
        "casual_photo": "NULL",
        "blurb": ""

    },
    {
        "id": 283.0,
        "name": "Andrea Tran",
        "gender": "F",
        "class": "Psi",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Psi/andreatran.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 284.0,
        "name": "Andrew Eck",
        "gender": "M",
        "class": "Psi",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/andrew-eck/",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Vice Regent",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Psi/andreweck.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 285.0,
        "name": "Ivan Huang",
        "gender": "M",
        "class": "Psi",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/ivanhuang136/",
        "major": "Electrical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Brotherhood",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Psi/ivanhuang.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 286.0,
        "name": "Jack Le",
        "gender": "M",
        "class": "Psi",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/hoang-jack-le-68b0b71bb/",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Psi/jackle.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 287.0,
        "name": "Nikolaj Kim",
        "gender": "M",
        "class": "Psi",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/nikolajkim/",
        "major": "Data Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Psi/nikolajkim.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 288.0,
        "name": "Tiffany Gao",
        "gender": "F",
        "class": "Psi",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Psi/tiffanygao.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 289.0,
        "name": "Tristan Zabala",
        "gender": "M",
        "class": "Psi",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Psi/tristanzabala.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 290.0,
        "name": "Diego Solorzano",
        "gender": "M",
        "class": "Gamma Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/diegoasolo/",
        "major": "Electrical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Engineering",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma%20Beta/diegosolorzano.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 291.0,
        "name": "Donovan Chen",
        "gender": "M",
        "class": "Gamma Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/donovanachen",
        "major": "Computer Science",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Scribe",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma%20Beta/donovanchen.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 292.0,
        "name": "Francisco (Tim) Oh",
        "gender": "M",
        "class": "Gamma Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/franciscotimoh/",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Corresponding Secretary",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma%20Beta/franciscooh.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 293.0,
        "name": "Hyungcheol Kim",
        "gender": "M",
        "class": "Gamma Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Brotherhood",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma%20Beta/hyungcheolkim.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 294.0,
        "name": "Jake Silverman",
        "gender": "M",
        "class": "Gamma Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/jakelsilverman/",
        "major": "Data Science",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Marshal",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma%20Beta/jakesilverman.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 295.0,
        "name": "Luke Vargas",
        "gender": "M",
        "class": "Gamma Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/luke-vargas-358179246/",
        "major": "Electrical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Regent",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Gamma%20Beta/lukevargas.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 296.0,
        "name": "Arim Song",
        "gender": "F",
        "class": "Delta Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/arimsong/",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Media and Chapter History",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta%20Beta/arimsong.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 297.0,
        "name": "Elise Ji",
        "gender": "F",
        "class": "Delta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta%20Beta/eliseji.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 298.0,
        "name": "Ellen So",
        "gender": "F",
        "class": "Delta Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/ellen-so/",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Recruitment",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta%20Beta/ellenso.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 299.0,
        "name": "Jamie Kuang",
        "gender": "F",
        "class": "Delta Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/jamiekuang/",
        "major": "Computer Science",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Treasurer",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta%20Beta/jamiekuang.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 300.0,
        "name": "Miguel Murillo",
        "gender": "M",
        "class": "Delta Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/miguelmurillo4/",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Recruitment",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta%20Beta/miguelmurillo.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 301.0,
        "name": "Natalie Perrochon",
        "gender": "F",
        "class": "Delta Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/natalie-perrochon",
        "major": "Computer Science",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Director of Website Development",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta%20Beta/natalieperrochon.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 302.0,
        "name": "Steven Lee",
        "gender": "M",
        "class": "Delta Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/steven-lee35/",
        "major": "Computer Science",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Director of Professional Development",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta%20Beta/stevenlee.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 303.0,
        "name": "Wilson Nguyen",
        "gender": "M",
        "class": "Delta Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/wilsonng17/",
        "major": "Computer Science",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Engineering",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta%20Beta/wilsonnguyen.jpeg",
        "casual_photo": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Delta%20Beta/wilsonnguyencasual.jpeg",
        "blurb": ""
    },
    {
        "id": 304.0,
        "name": "Austin Fugate",
        "gender": "M",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon%20Beta/austinfugate.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 305.0,
        "name": "Custo Yang",
        "gender": "M",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Fundraising",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon%20Beta/custoyang.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 306.0,
        "name": "Diane Yoon",
        "gender": "F",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Aerospace Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon%20Beta/dianeyoon.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 307.0,
        "name": "Elena Cheung",
        "gender": "F",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Director of Service",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon%20Beta/elenacheung.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 308.0,
        "name": "Keiko Yamamuro",
        "gender": "F",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Media and Chapter History",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon%20Beta/keikoyamamuro.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 309.0,
        "name": "Lance Vu",
        "gender": "M",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/lancevu/",
        "major": "Software Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon%20Beta/lancevu.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 310.0,
        "name": "Luis Gama",
        "gender": "M",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Co-Director of Fundraising",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon%20Beta/luisgama.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 312.0,
        "name": "Sarah Qu",
        "gender": "F",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 313.0,
        "name": "Shana Chao",
        "gender": "F",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/shanachao/",
        "major": "Computer Engineering",
        "cabby_exec_status": "Y",
        "cabby_exec_position": "Director of Public Relations",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon%20Beta/shanachao.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 314.0,
        "name": "Xamantha De Luna",
        "gender": "F",
        "class": "Epsilon Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Epsilon%20Beta/xamanthadeluna.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 315.0,
        "name": "Annie Tran",
        "gender": "F",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta%20Beta/annietran.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 316.0,
        "name": "Carol Wang",
        "gender": "F",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Computer Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Random/no_photo_available.jpg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 317.0,
        "name": "Dalena Nguyen",
        "gender": "F",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "https://www.linkedin.com/in/dalena-nguyen7/",
        "major": "Computer Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta%20Beta/dalenanguyen.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 318.0,
        "name": "David Culciar",
        "gender": "M",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Computer Science and Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta%20Beta/davidculciar.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 319.0,
        "name": "Ethan Choi",
        "gender": "M",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta%20Beta/ethanchoi.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 320.0,
        "name": "Hannah Kim",
        "gender": "F",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta%20Beta/hannahkim.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 321.0,
        "name": "Jason Nguyen",
        "gender": "M",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Civil Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta%20Beta/jasonnguyen.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 322.0,
        "name": "Joseph de Leon",
        "gender": "M",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Data Science",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta%20Beta/josephdeleon.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 323.0,
        "name": "Katie Quach",
        "gender": "F",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Biomedical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta%20Beta/katiequach.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
    {
        "id": 324.0,
        "name": "Zane Xing",
        "gender": "M",
        "class": "Zeta Beta",
        "active_status": "Y",
        "linkedin_url": "NULL",
        "major": "Mechanical Engineering",
        "cabby_exec_status": "N",
        "cabby_exec_position": "NULL",
        "profile_url": "https://raw.githubusercontent.com/Theta-Tau-at-UCI/pi-delta-brothers-images/main/Zeta%20Beta/zanexing.jpeg",
        "casual_photo": "NULL",
        "blurb": ""
    },
]

